import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useRecoilValue } from "recoil";

import { IntroductionMessage } from "../molecules/IntroductionMessage";
import { HopeButtons } from "../molecules/HopeButtons";
import { ProcedureFlow } from "../molecules/ProcedureFlow";
import { FinancialInstitution } from "../molecules/FinancialInstitution";
import { Inquiry } from "../molecules/Inquiry";
import { Footer } from "../molecules/Footer";
import { AccountTransferMessage } from "../molecules/AccountTransferMessage";
import { financialInstitutionUrlState } from "../../recoil/financialInstitutionUrlState";
import { FooterCredit } from "../atoms/FooterCredit";

export const InvitationDialog = () => {
  const financialInstitution = useRecoilValue(financialInstitutionUrlState);
  const information = (
    <>
      <IntroductionMessage key="introductionMessage" />
      <ProcedureFlow key="procedureFlow" />
      <AccountTransferMessage key="accountTransferMessage" />
      {financialInstitution && (
        <FinancialInstitution key="financialInstitution" />
      )}
    </>
  );

  const action = (
    <DialogActions sx={{ textAlign: "center" }} disableSpacing={true}>
      <HopeButtons />
    </DialogActions>
  );
  return (
    <Dialog
      id="invitation-dialog"
      open={true}
      maxWidth={"md"}
      BackdropProps={{
        sx: { background: "rgba(230,230,230,0.2)" },
      }}
    >
      <DialogTitle
        sx={{ bgcolor: "primary.dark", color: "white", fontSize: "24px" }}
      >
        口座振替手続き受付開始のご案内
      </DialogTitle>
      <DialogContent dividers={true}>
        {information}
        <Inquiry />
        <Footer />
        <FooterCredit />
      </DialogContent>
      {action}
    </Dialog>
  );
};
