import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useRecoilState } from "recoil";
import { axiosErrorState } from "../../recoil/axiosErrorState";

export const ErrorAlert = () => {
  const [axiosError, setAxiosError] = useRecoilState(axiosErrorState);
  const handleClose = () => {
    setAxiosError(false);
  };

  return (
    <Snackbar
      open={axiosError}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        内部エラーが発生しました。
        <br />
        お手数をおかけしますが、しばらく時間を空けてから実施してください。
      </Alert>
    </Snackbar>
  );
};
