import { atom } from "recoil";

export const invitationClientIdState = atom({
  key: "invitationClientIdState",
  default: "",
});

export const contactAddressState = atom({
  key: "contactAddressState",
  default: "",
});
