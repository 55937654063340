import { atom } from "recoil";
type transferRequestForm = {
  transferRequestFormDownloadUrl: string;
  transferRequestFormReturnName: string; // 口座振替依頼書返送先宛名
  transferRequestFormReturnAddress: string; // 口座振替依頼書返送先住所
  expiredAt: string;
};
export const transferRequestFormState = atom<transferRequestForm>({
  key: "transferRequestFormState",
  default: {
    transferRequestFormDownloadUrl: "",
    transferRequestFormReturnName: "", // 口座振替依頼書返送先宛名
    transferRequestFormReturnAddress: "",
    expiredAt: "",
  },
});
