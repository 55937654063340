import Typography from "@mui/material/Typography";
import { ChapterTitle } from "../atoms/ChapterTitle";

export const AccountTransferMessage = () => {
  return (
    <>
      <ChapterTitle title="振替について" />
      <Typography
        variant="body1"
        component={"div"}
        sx={{ ml: 5, my: 2 }}
        gutterBottom
      >
        <ul>
          <li>引落手数料：無料</li>
          <li>請求書の発行日：通常の請求書と同日に発行・送信</li>
        </ul>
      </Typography>
    </>
  );
};
