import Typography from "@mui/material/Typography";

export const IntroductionMessage = () => {
  return (
    <>
      <Typography
        id="introduction"
        variant="body1"
        component={"span"}
        sx={{ margin: 1 }}
        gutterBottom
      >
        この度、お取引様の決済手段の拡充のために、口座振替手続きの受付を開始したことをご案内いたします。
        <br />
        口座振替のお手続きをしていただくことで、振込手数料の削減、支払いかかる業務負担の軽減が可能となります。
        <br />
        ご利用までの流れ等をご確認いただき、ご希望いただく場合は本画面の「希望する」を押下してください。
      </Typography>
    </>
  );
};
