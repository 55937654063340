import { useAuth } from "../../hooks/auth/useAuth";
import { DownloadDialog } from "../organisms/DownloadDialog";
import { GenericTemplate } from "../templates/GenericTemplate";

export const DownloadPage = () => {
  useAuth();

  return (
    <GenericTemplate>
      <DownloadDialog />
    </GenericTemplate>
  );
};
