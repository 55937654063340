import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { InvitationPage } from "./components/pages/InvitationPage";
import { InvalidUrlPage } from "./components/pages/invalidUrlPage";
import { AnsweredPage } from "./components/pages/AnsweredPage";
import { LoginPage } from "./components/pages/LoginPage";
import { DownloadPage } from "./components/pages/DownloadPage";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="invitation/:answerPageId" element={<InvitationPage />} />
          <Route path="answered/:answerPageId" element={<AnsweredPage />} />
          <Route path="download/:downloadPageId" element={<DownloadPage />} />
          <Route path="*" element={<InvalidUrlPage />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
