import Button from "@mui/material/Button";

export const InformationButton = () => {
  return (
    <Button
      id="information-button"
      href="https://www.makeleaps.jp/"
      sx={{ fontSize: "12pt" }}
      variant="outlined"
    >
      RICOH 口座振替サービス（外部サイト）
    </Button>
  );
};
