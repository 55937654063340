import Typography from "@mui/material/Typography";

export const DownloadIntroductionMessage = () => {
  return (
    <>
      <Typography
        id="download-introduction"
        variant="body1"
        component={"div"}
        sx={{ margin: 1 }}
        gutterBottom
      >
        口座振替の利用開始に向けて、引き続きSTEP
        3以降のお手続きをご実施ください。
      </Typography>
    </>
  );
};
