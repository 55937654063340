import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { FooterCredit } from "../atoms/FooterCredit";
import {
  AcceptedMessage,
  AcceptedMessageProps,
} from "../molecules/AcceptedMessage";
import { ErrorAlert } from "../molecules/ErrorAlert";
import { Inquiry } from "../molecules/Inquiry";

export const AnsweredDialog = (props: AcceptedMessageProps) => {
  return (
    <>
      <Dialog
        id="answered-dialog"
        open={true}
        maxWidth={"md"}
        BackdropProps={{
          sx: { background: "rgba(230,230,230,0.2)" },
        }}
      >
        <DialogTitle
          sx={{ bgcolor: "primary.dark", color: "white", fontSize: "24px" }}
        >
          回答受付完了
        </DialogTitle>
        <DialogContent dividers={true}>
          <AcceptedMessage answer={props.answer} />
          <ErrorAlert />
          <Inquiry />
          <FooterCredit />
        </DialogContent>
      </Dialog>
    </>
  );
};
