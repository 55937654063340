import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

type Props = {
  title: string;
};

export const Header: React.VFC<Props> = (props: Props) => {
  const { title } = props;

  return (
    <AppBar sx={{ bgcolor: "secondary.main" }} position="sticky">
      <Toolbar>
        <Typography component={"span"} id="toolbar_title">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
