import Box from "@mui/material/Box";
import { memo, VFC } from "react";

export const Footer: VFC = memo(() => {
  return (
    <Box sx={{ marginTop: 2, textAlign: "center" }}>
      <footer>(c) Ricoh Co., Ltd.</footer>
    </Box>
  );
});
