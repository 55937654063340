import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DesktopWindowsRoundedIcon from "@mui/icons-material/DesktopWindowsRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Box from "@mui/material/Box";

import { FrowItem } from "../atoms/FlowItem";
import { ChapterTitle } from "../atoms/ChapterTitle";
import { Notes } from "../atoms/Notes";
import blueGrey from "@mui/material/colors/blueGrey";

export const DownloadFlow = () => {
  const flowStyles = { fontSize: 40, py: 0, mr: 2 };

  const items = [
    {
      title: "STEP1",
      icon: <CheckCircleOutlineIcon sx={flowStyles} />,
      children:
        "口座振替手続き受付開始のご案内画面の下部にある「希望する」ボタンを押下します",
      color: blueGrey[100],
    },
    {
      title: "STEP2",
      icon: <MailOutlineRoundedIcon sx={flowStyles} />,
      children: "受付完了メールが届きます",
      color: blueGrey[100],
    },
    {
      title: "STEP3",
      icon: <DesktopWindowsRoundedIcon sx={flowStyles} />,
      children:
        "本画面の下部にある「口座振替依頼書をダウンロード」ボタンを押下し、ダウンロードしたPDFファイルを印刷します",
      border: "3pt",
      borderColor: blueGrey[200],
    },
    {
      title: "STEP4",
      icon: <EditRoundedIcon sx={flowStyles} />,
      children: "口座振替依頼書の必要事項の記入・捺印をします",
    },
    {
      title: "STEP5",
      icon: <SendRoundedIcon sx={flowStyles} />,
      children: "口座振替依頼書を以下の郵送先に送付します",
    },
  ];

  const flow = items.map((item, index) => (
    <Box
      key={`download-box-${index}`}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <FrowItem
        key={`download-item-${index}`}
        title={item.title}
        children={item.children}
        icon={item.icon}
        color={item.color}
        border={item.border}
        borderColor={item.borderColor}
      ></FrowItem>

      {index < items.length - 1 && (
        <KeyboardArrowDownRoundedIcon
          sx={{
            fontSize: 60,
            display: "flex",
            mx: "auto",
            color: item.color,
          }}
        />
      )}
    </Box>
  ));

  return (
    <>
      <ChapterTitle title="ご利用までの流れ" />
      {flow}
      <Notes />
    </>
  );
};
