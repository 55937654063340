import Box from "@mui/material/Box";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useRecoilValue } from "recoil";
import Typography from "@mui/material/Typography";

import { contactAddressState } from "../../recoil/contactState";

export const PartnerContactAddress = () => {
  const mailAddress = useRecoilValue(contactAddressState);
  return (
    <Box sx={{ margin: "10px" }}>
      <Typography
        id="partnerEmail"
        variant="body1"
        component={"span"}
        gutterBottom
      >
        <Box sx={{ fontWeight: "bold" }}>
          口座振替依頼書に関わるお問い合わせ先
        </Box>
        <MailOutlineIcon sx={{ verticalAlign: "middle", margin: "5px" }} />
        {mailAddress}
      </Typography>
    </Box>
  );
};
