import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ChapterTitle } from "../atoms/ChapterTitle";
import { FinancialInstitutionButton } from "../atoms/FinancialInstitutionButton";

export const FinancialInstitution = () => {
  return (
    <>
      <ChapterTitle title="取扱金融機関" />
      <Box sx={{ ml: 5, my: 2 }}>
        <Typography variant="body1" component={"div"} gutterBottom>
          取扱金融機関はこちらからご確認いただけます。
        </Typography>
        <FinancialInstitutionButton />
      </Box>
    </>
  );
};
