import Button from "@mui/material/Button";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick?: () => void;
  id: string;
  color: "primary" | "secondary";
};
export const PrimaryButton = (props: Props) => {
  const { children, onClick, color, id } = props;
  return (
    <Button
      variant="contained"
      color={color}
      onClick={onClick}
      id={id}
      key={id}
      sx={{
        height: "50px",
        width: "40%",
        margin: "10px",
        fontSize: "16pt",
      }}
    >
      {children}
    </Button>
  );
};
