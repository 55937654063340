import Box from "@material-ui/core/Box";
import blueGrey from "@mui/material/colors/blueGrey";
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Typography from "@mui/material/Typography";

import { Header } from "../molecules/Header";
import { NavBar } from "../organisms/navBar";

export interface GenericTemplateProps {
  children: React.ReactNode;
}

const theme = createTheme({
  palette: {
    secondary: { main: blueGrey[200] },
  },
});

export const GenericTemplate = (props: GenericTemplateProps) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Header title={"RICOH 支払管理ポータル"}></Header>
        <Box sx={{ display: "flex" }}>
          <NavBar />
          <Box
            sx={{
              width: "calc(100% - 280px)",
              display: "flex",
              flexDirection: "column",
              minHeight: "calc(100vh - 64px)",
            }}
          >
            <Container
              maxWidth="lg"
              sx={{
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
                width: "90%",
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
              ></Typography>
              {props.children}
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};
