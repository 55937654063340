import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { saveAs } from "file-saver";

import { axiosErrorState } from "../../recoil/axiosErrorState";
import { transferRequestFormState } from "../../recoil/transferRequestFormState";
import { invitationClientIdState } from "../../recoil/contactState";
import { NotFoundFileDialog } from "../molecules/NotFoundFileDialog";
import { notFoundFileDialogState } from "../../recoil/dialogState";

export const DownloadButton = () => {
  const url = process.env.REACT_APP_API || "http://localhost:3000";
  const setAxiosError = useSetRecoilState(axiosErrorState);
  const transferRequestState = useRecoilValue(transferRequestFormState);
  const clientId = useRecoilValue(invitationClientIdState);
  let fileName = "";
  if (transferRequestState.transferRequestFormDownloadUrl) {
    fileName =
      transferRequestState.transferRequestFormDownloadUrl.split("/")[4] || "";
  }
  const setDialogIsOpen = useSetRecoilState(notFoundFileDialogState);

  const downloadForm = async () => {
    // axiosでダウンロードする処理
    try {
      const res = await axios.get(
        `${url}/api/companyInformation/transferRequestForm`,
        {
          params: {
            downloadUrl: encodeURI(
              transferRequestState.transferRequestFormDownloadUrl
            ),
            invitationClientId: clientId,
          },
          responseType: "blob",
          headers: { "Content-Type": "application/pdf" },
        }
      );

      if (res.status === 200) {
        saveAs(res.data, fileName);

        try {
          // イベントを登録する
          const eventRes = await axios.post(
            `${url}/api/invitation/${clientId}/operation`,
            {
              event: "accountTransferDownloaded",
            }
          );
          if (eventRes.status === 204) {
          }
        } catch {}
      }
    } catch (e: any) {
      if (e.response.status === 400) {
        // ファイルがDBまたはS3にない場合はダイアログを表示する
        setDialogIsOpen(true);
      }
      setAxiosError(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          id="download-button"
          startIcon={<DownloadIcon />}
          onClick={downloadForm}
          variant="contained"
          sx={{
            fontSize: "16pt",
          }}
        >
          口座振替依頼書ダウンロード
        </Button>
        <NotFoundFileDialog />
        <Typography
          id="download-expiry-date"
          sx={{
            fontSize: "16pt",
            margin: "10px",
          }}
          component={"div"}
        >
          （DL期限：{transferRequestState.expiredAt}）
        </Typography>
      </Box>
    </>
  );
};
