import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { completeDialogState } from "../../recoil/dialogState";
import { loginState } from "../../recoil/loginState";

export const FinishingDialog = () => {
  const [dialogIsOpen, setDialogIsOpen] = useRecoilState(completeDialogState);
  const navigate = useNavigate();
  const loginId = useRecoilValue(loginState);

  const handleClose = () => {
    setDialogIsOpen(false);
    navigate(`/answered/${loginId}?ans=agreement`);
  };

  return (
    <Dialog id="complete-dialog" open={dialogIsOpen}>
      <DialogTitle>口座振替受付完了</DialogTitle>
      <DialogContent sx={{ margin: 1 }}>
        受付完了メールを送信いたしました。
        <br />
        メールに記載されているURLより、口座振替依頼書のダウンロードをお願いいたします。
      </DialogContent>
      <DialogActions>
        <Button id="close-complete-dialog" onClick={handleClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
