import Typography from "@mui/material/Typography";

type Props = {
  title: string;
};
export const ChapterTitle = (props: Props) => {
  const { title } = props;
  return (
    <Typography
      variant="h5"
      component={"div"}
      sx={{ fontWeight: "bold", m: 2 }}
      gutterBottom
    >
      {title}
    </Typography>
  );
};
