import axios from "axios";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Box from "@mui/material/Box";

import {
  completeDialogState,
  confirmationDialogState,
} from "../../recoil/dialogState";
import { PrimaryButton } from "../atoms/PrimaryButton";
import { FinishingDialog } from "./FinishingDialog";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { invitationClientIdState } from "../../recoil/contactState";
import { axiosErrorState } from "../../recoil/axiosErrorState";
import { ANSWER } from "../../types/answer";

export const HopeButtons = () => {
  const url = process.env.REACT_APP_API || "http://localhost:3000";
  const setDialogIsOpen = useSetRecoilState(completeDialogState);
  const setConfirmationDialogIsOpen = useSetRecoilState(
    confirmationDialogState
  );
  const clientId = useRecoilValue(invitationClientIdState);

  const setAxiosError = useSetRecoilState(axiosErrorState);

  const updateAnswer = async (answer: ANSWER) => {
    //axiosで回答ステータスを更新する処理
    try {
      const res = await axios
        .create({ validateStatus: (status) => status < 500 })
        .put<string>(`${url}/api/invitation/${clientId}/answer`, { answer });
      if (res.status === 204) return true;
      if (res.data.startsWith("already answered ")) {
        // FIXME: ほぼないケースだが、例外処理として入れている。暫定で、alert出すだけになっているが、ちゃんとダイアログにしたほうが親切ではある。
        const answer =
          res.data === "already answered 'agreement'."
            ? "希望する"
            : "希望しない";
        alert(
          `口座振替の手続きを「${answer}」でご回答済みです。\n画面を再読み込みしてください。`
        );
      }
      return false;
    } catch (e) {
      setAxiosError(true);
      return false;
    }
  };

  const sendReceptionMail = async () => {
    //axiosで回答ステータスを更新する処理
    try {
      await axios.post(`${url}/api/invitation/reception`, {
        invitationClientId: clientId,
      });
    } catch (e) {
      // ClientFront側では特にエラーを表示しない。
      // TODO: 送信側での再送だけでなく、受信側から要求できたほうがいいのか？
    }
  };

  const openDialog = async () => {
    const isSuccess = await updateAnswer("agreement");
    if (isSuccess) {
      setDialogIsOpen(true);
      await sendReceptionMail(); // NOTE: メール送信結果またなくていいけど、今のところ await しとこう。
    }
  };
  const openConfirmationDialog = () => {
    setConfirmationDialogIsOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PrimaryButton
          id="disagree-button"
          color="secondary"
          onClick={openConfirmationDialog}
        >
          希望しない
        </PrimaryButton>
        <ConfirmationDialog onClick={updateAnswer} />
        <PrimaryButton id="agree-button" color="primary" onClick={openDialog}>
          希望する
        </PrimaryButton>
        <FinishingDialog />
      </Box>
    </>
  );
};
