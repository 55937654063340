import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { DownloadButton } from "../atoms/DownloadButton";
import { FooterCredit } from "../atoms/FooterCredit";
import { DownloadFlow } from "../molecules/DownloadFlow";
import { DownloadIntroductionMessage } from "../molecules/DownloadIntroductionMessage";
import { Footer } from "../molecules/Footer";
import { Inquiry } from "../molecules/Inquiry";
import { TransferRequestFormReturnInformation } from "../molecules/TransferRequestFormReturnInformation";

export const DownloadDialog = () => {
  return (
    <Dialog
      id="download-dialog"
      open={true}
      maxWidth={"md"}
      BackdropProps={{
        sx: { background: "rgba(230,230,230,0.2)" },
      }}
    >
      <DialogTitle
        sx={{ bgcolor: "primary.dark", color: "white", fontSize: "24px" }}
      >
        口座振替依頼書のダウンロード
      </DialogTitle>
      <DialogContent dividers={true}>
        <DownloadIntroductionMessage />
        <DownloadFlow />
        <TransferRequestFormReturnInformation />
        <Inquiry />
        <Footer />
        <FooterCredit />
      </DialogContent>
      <DialogActions>
        <DownloadButton />
      </DialogActions>
    </Dialog>
  );
};
