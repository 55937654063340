import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/auth/useAuth";
import { ErrorAlert } from "../molecules/ErrorAlert";
import { AnsweredDialog } from "../organisms/AnsweredDialog";
import { GenericTemplate } from "../templates/GenericTemplate";

export const AnsweredPage = () => {
  const search = useLocation().search;
  const answer = new URLSearchParams(search).get("ans") || "";
  useAuth();
  return (
    <>
      <GenericTemplate>
        <AnsweredDialog answer={answer} />
        <ErrorAlert />
      </GenericTemplate>
    </>
  );
};
