import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import { ANSWER } from "../../types/answer";
import { loginState } from "../../recoil/loginState";
import { confirmationDialogState } from "../../recoil/dialogState";

type Props = {
  onClick: (answer: ANSWER) => Promise<boolean>;
};

export const ConfirmationDialog = (props: Props) => {
  const { onClick } = props;
  const [dialogIsOpen, setDialogIsOpen] = useRecoilState(
    confirmationDialogState
  );
  const navigate = useNavigate();
  const loginId = useRecoilValue(loginState);

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  // 確認ダイアログで「はい」を選択したら、回答を「希望しない（DISAGREE）」で更新する
  const handleConfirm = async () => {
    const isSuccess = await onClick("disagree");
    handleClose();
    isSuccess && navigate(`/answered/${loginId}?ans=disagree`);
  };

  return (
    <Dialog id="confirmation-dialog" open={dialogIsOpen}>
      <DialogTitle>
        <ErrorOutlineRoundedIcon
          sx={{ verticalAlign: "middle", margin: "5px" }}
        />
        確認
      </DialogTitle>
      <DialogContent>「希望しない」を選択してよろしいですか</DialogContent>
      <DialogActions>
        <Button id="confirmation-no" onClick={handleClose}>
          いいえ
        </Button>
        <Button id="confirmation-yes" onClick={handleConfirm}>
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
};
