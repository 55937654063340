import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { FooterCredit } from "../atoms/FooterCredit";
import { SystemContactAddress } from "../atoms/SystemContactAddress";
import { ErrorAlert } from "../molecules/ErrorAlert";

export const InvalidUrlDialog = () => {
  return (
    <>
      <Dialog
        id="invalid-dialog"
        open={true}
        maxWidth={"md"}
        BackdropProps={{
          sx: { background: "rgba(230,230,230,0.2)" },
        }}
      >
        <DialogTitle>無効なURL</DialogTitle>
        <DialogContent>
          URLの期限が切れたり、URLが無効になった場合にこの画面が表示されます。
          <br />
          <ErrorAlert />
          <SystemContactAddress />
          <FooterCredit />
        </DialogContent>
      </Dialog>
    </>
  );
};
