import { atom } from "recoil";

export const completeDialogState = atom({
  key: "completeDialogState",
  default: false,
});

export const confirmationDialogState = atom({
  key: "confirmationDialogState",
  default: false,
});

export const notFoundFileDialogState = atom({
  key: "notFoundFileDialogState",
  default: false,
});
