import { ErrorAlert } from "../molecules/ErrorAlert";
import { InvalidUrlDialog } from "../organisms/InvalidUrlDialog";
import { GenericTemplate } from "../templates/GenericTemplate";

export const InvalidUrlPage = () => {
  return (
    <>
      <GenericTemplate>
        <InvalidUrlDialog />
        <ErrorAlert />
      </GenericTemplate>
    </>
  );
};
