import { Box, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { transferRequestFormState } from "../../recoil/transferRequestFormState";
import { ChapterTitle } from "../atoms/ChapterTitle";

export const TransferRequestFormReturnInformation = () => {
  const transferRequestState = useRecoilValue(transferRequestFormState);

  return (
    <>
      <ChapterTitle title="郵送先" />
      <Box sx={{ ml: 5, my: 2 }}>
        <Typography id="returnInfo" variant="body1" component={"div"} gutterBottom>
          郵送先：{transferRequestState.transferRequestFormReturnAddress}
          <br />
          宛名：{transferRequestState.transferRequestFormReturnName}
        </Typography>
      </Box>
    </>
  );
};
