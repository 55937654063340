import Box from "@mui/material/Box";

import { ChapterTitle } from "../atoms/ChapterTitle";
import { ContactId } from "../atoms/ContactId";
import { InformationButton } from "../atoms/InformationButton";
import { PartnerContactAddress } from "../atoms/PartnerContactAddress";
import { SystemContactAddress } from "../atoms/SystemContactAddress";

export const Inquiry = () => {
  return (
    <>
      <ChapterTitle title="お問い合わせ" />
      <Box sx={{ ml: 5, my: 2 }}>
        <ContactId />
        <PartnerContactAddress />
        <SystemContactAddress />
        <InformationButton />
      </Box>
    </>
  );
};
