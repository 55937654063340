import Box from "@mui/material/Box";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Typography from "@mui/material/Typography";

export const SystemContactAddress = () => {
  return (
    <Box sx={{ margin: "10px" }}>
      <Typography
        id="systemEmail"
        variant="body1"
        component={"span"}
        gutterBottom
      >
        <Box sx={{ fontWeight: "bold" }}>システムに関するお問い合わせ先</Box>
        <MailOutlineIcon sx={{ verticalAlign: "middle", margin: "5px" }} />
        zjc_fintech_support@jp.ricoh.com
      </Typography>
    </Box>
  );
};
