export const FooterCredit = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "right",
      }}
    >
      <a
        href="https://credit.fintech.digital.ricoh.com/3rdpartylicenses.txt"
        style={{ fontSize: "9px", margin: "3px", textDecoration: "none" }}
      >
        ライセンス
      </a>
      <a
        href="https://jp.ricoh.com/privacy/index_2.html"
        style={{ fontSize: "9px", margin: "3px", textDecoration: "none" }}
      >
        プライバシーポリシー
      </a>
    </div>
  );
};
