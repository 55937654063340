import Typography from "@mui/material/Typography";

export type AcceptedMessageProps = {
  answer: string;
};

export const AcceptedMessage = (props: AcceptedMessageProps) => {
  return (
    <Typography
      variant="body1"
      component={"span"}
      sx={{ margin: 1 }}
      gutterBottom
    >
      ご回答いただきありがとうございました。
      <br />
      {props.answer === "agreement" && (
        <div>
          受付完了メールを受信していない場合は、お手数ですが下記のお問い合わせ先にお問い合わせください。
        </div>
      )}
    </Typography>
  );
};
