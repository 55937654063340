import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  icon: ReactNode;
  title: string;
  color?: string;
  border?: string;
  borderColor?: string;
};
export const FrowItem = (props: Props) => {
  const { icon, children, title, color, border, borderColor } = props;
  const cardColor = color || "secondary.main";

  return (
    <Card
      variant="outlined"
      color={cardColor}
      sx={{
        borderWidth: border,
        borderColor: borderColor,
        width: "90%",
        mx: "auto",
        my: -1,
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CardHeader
          sx={{
            bgcolor: cardColor,
            color: "white",
          }}
          title={title}
          titleTypographyProps={{ variant: "h6" }}
        ></CardHeader>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: color,
              height: 30,
            }}
          >
            {icon}
            {children}
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};
