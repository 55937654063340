import { useAuth } from "../../hooks/auth/useAuth";
import { ErrorAlert } from "../molecules/ErrorAlert";

export const LoginPage = () => {
  useAuth();

  return (
    <>
      loading...
      <ErrorAlert />
    </>
  );
};
