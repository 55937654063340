import { useAuth } from "../../hooks/auth/useAuth";
import { ErrorAlert } from "../molecules/ErrorAlert";
import { InvitationDialog } from "../organisms/InvitationDialog";
import { GenericTemplate } from "../templates/GenericTemplate";

export const InvitationPage = () => {
  useAuth();
  return (
    <>
      <GenericTemplate>
        <InvitationDialog /> <ErrorAlert />
      </GenericTemplate>
    </>
  );
};
