import Typography from "@mui/material/Typography";
import { useRecoilValue } from "recoil";

import { invitationClientIdState } from "../../recoil/contactState";

export const ContactId = () => {
  const clientId = useRecoilValue(invitationClientIdState);
  return (
    <Typography variant="body1" component={"span"} gutterBottom>
      <div id="clientId">{`お問い合わせID：${clientId}`}</div>
      お問い合わせの際は、上記のお問い合わせIDもご記載ください
    </Typography>
  );
};
