import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useEffect } from "react";

import {
  contactAddressState,
  invitationClientIdState,
} from "../../recoil/contactState";
import { loginState } from "../../recoil/loginState";
import { InvitationPageStatusResponse } from "../../types/InvitationPageStatusResponse";
import { axiosErrorState } from "../../recoil/axiosErrorState";
import { DownloadPageResponse } from "../../types/DownloadPageResponse";
import { transferRequestFormState } from "../../recoil/transferRequestFormState";
import { financialInstitutionUrlState } from "../../recoil/financialInstitutionUrlState";

export const useAuth = () => {
  const location = useLocation();
  const path = location.pathname;
  const search = location.search;
  const setContactAddress = useSetRecoilState(contactAddressState);
  const setInvitationClientId = useSetRecoilState(invitationClientIdState);
  const [loginId, setLoginId] = useRecoilState(loginState);
  const setAxiosError = useSetRecoilState(axiosErrorState);
  const setTransferRequestForm = useSetRecoilState(transferRequestFormState);
  const setFinancialInstitutionUrlState = useSetRecoilState(
    financialInstitutionUrlState
  );
  const params = useParams();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API || "http://localhost:3000";
  const axiosSetting = axios.create({
    baseURL: url,
    timeout: 2000,
  });
  const answerPageType = ["answer", "invitation", "answered"];

  const pageType =
    new URLSearchParams(search).get("type") || path.split("/")[1];

  useEffect(() => {
    (async () => {
      if (!loginId) {
        if (answerPageType.includes(pageType)) {
          const answerPageId =
            new URLSearchParams(search).get("id") || params.answerPageId;
          try {
            const res = await axiosSetting.get<InvitationPageStatusResponse>(
              `/api/invitation/answer`,
              {
                params: { id: answerPageId },
              }
            );
            if (res.status === 200) {
              const data = res.data;
              setInvitationClientId(data.invitationClientId);
              setContactAddress(data.replyToAddress!);
              setLoginId(answerPageId!);
              if (data.financialInstitutionUrl) {
                setFinancialInstitutionUrlState(data.financialInstitutionUrl);
              }
              if (data.answerToInvitation) {
                navigate(
                  `/answered/${answerPageId}?ans=${data.answerToInvitation}`
                );
              } else if (data.isExpired) {
                navigate("/not-found");
              } else {
                navigate(`/invitation/${answerPageId}`);
              }
            }
          } catch (error: any) {
            if (!error.response) {
              setAxiosError(true);
            }
            navigate("/not-found");
          }
        } else if (pageType === "download") {
          const downloadPageId =
            new URLSearchParams(search).get("id") || params.downloadPageId;
          try {
            const res = await axiosSetting.get<DownloadPageResponse>(
              `/api/invitation/download`,
              {
                params: { id: downloadPageId },
              }
            );
            if (res.status === 200) {
              const data = res.data;
              setInvitationClientId(data.invitationClientId);
              setContactAddress(data.replyToAddress!);
              setLoginId(downloadPageId!);
              if (data.isExpired) {
                navigate("/not-found");
              } else {
                setTransferRequestForm({
                  transferRequestFormDownloadUrl:
                    data.transferRequestFormDownloadUrl,
                  transferRequestFormReturnName:
                    data.transferRequestFormReturnName,
                  transferRequestFormReturnAddress:
                    data.transferRequestFormReturnAddress,
                  expiredAt: data.expiredAt,
                });
                navigate(`/download/${downloadPageId}`);
              }
            }
          } catch (error: any) {
            if (!error.response) {
              setAxiosError(true);
            }
            navigate("/not-found");
          }
        } else {
          navigate("/not-found");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setContactAddress, setInvitationClientId, setLoginId]);
};
