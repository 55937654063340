import { memo } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InsightsIcon from "@mui/icons-material/Insights";
import DescriptionIcon from "@mui/icons-material/Description";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import HelpIcon from "@mui/icons-material/Help";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

export const NavBar = memo(() => {
  return (
    <Box
      sx={{
        bgcolor: "background.default",
        width: "280px",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <List component="div" disablePadding>
        <ListItemButton id="nav-safetradingDashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="ダッシュボード" />
        </ListItemButton>
        <ListItemButton id="nav-safetradingPlan">
          <ListItemIcon>
            <InsightsIcon />
          </ListItemIcon>
          <ListItemText primary="債務一覧" />
        </ListItemButton>
        <ListItemButton id="nav-safetradingCreditList">
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="支払管理" />
        </ListItemButton>

        <ListItemButton id="nav-safetradingCreditList">
          <ListItemIcon>
            <AddBusinessIcon />
          </ListItemIcon>
          <ListItemText primary="取引先管理" />
        </ListItemButton>
      </List>

      <Divider />
      <List>
        <ListItemButton id="nav-help">
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="ヘルプ" />
        </ListItemButton>
        <ListItemButton id="nav-help">
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="お問い合わせ" />
        </ListItemButton>
      </List>
    </Box>
  );
});
