import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useRecoilState } from "recoil";

import { notFoundFileDialogState } from "../../recoil/dialogState";

export const NotFoundFileDialog = () => {
  const [dialogIsOpen, setDialogIsOpen] = useRecoilState(
    notFoundFileDialogState
  );

  const handleClose = () => {
    setDialogIsOpen(false);
  };
  return (
    <Dialog id="not-found-file-dialog" open={dialogIsOpen}>
      <DialogTitle>ファイルをダウンロードできません</DialogTitle>
      <DialogContent sx={{ margin: 1 }}>
        口座振替依頼書のファイルが削除された可能性があります。
        <br />
        お手数をおかけしますが、口座振替依頼書に関わるお問い合わせ先にご連絡をお願いいたします。
      </DialogContent>
      <DialogActions>
        <Button id="close-not-found-file-dialog" onClick={handleClose}>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};
