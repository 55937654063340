import Button from "@mui/material/Button";
import axios from "axios";
import saveAs from "file-saver";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { axiosErrorState } from "../../recoil/axiosErrorState";
import { financialInstitutionUrlState } from "../../recoil/financialInstitutionUrlState";

export const FinancialInstitutionButton = () => {
  const url = process.env.REACT_APP_API || "http://localhost:3000";
  const financialInstitution = useRecoilValue(financialInstitutionUrlState);
  const setAxiosError = useSetRecoilState(axiosErrorState);

  const onClick = async () => {
    try {
      const res = await axios.get(
        `${url}/api/companyInformation/financialInstitution`,
        {
          params: {
            financialInstitutionUrl: encodeURI(financialInstitution),
          },
          responseType: "blob",
          headers: { "Content-Type": "application/pdf" },
        }
      );
      if (res.status === 200) {
        saveAs(res.data, financialInstitution.split("/")[2]);
      }
    } catch {
      setAxiosError(true);
    }
  };

  return (
    <Button
      sx={{ fontSize: "12pt" }}
      variant="outlined"
      onClick={onClick}
      id="financial-institution-button"
    >
      取扱金融機関一覧ダウンロード
    </Button>
  );
};
