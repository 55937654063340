import Typography from "@mui/material/Typography";

export const Notes = () => {
  return (
    <>
      <Typography
        variant="body1"
        component={"div"}
        sx={{ ml: 5, my: 2 }}
        gutterBottom
      >
        <ul>
          <li>手続きが完了し、口座引落が開始されるまで2か月程度要します</li>
          <li>口座振替依頼書は、必ず貴社でコピー等により保管をお願いします</li>
        </ul>
      </Typography>
    </>
  );
};
